<template>
  <div>
    <LayoutsBaseNavigationLinkPrimary
      v-if="$access.global.read || $access.global.write"
      :to="$link.index()"
      icon="chart-pie"
      :text="$t('nav.dashboard')"
    />

    <LayoutsBaseNavigationSubNav
      v-if="
        $access.users.read ||
        $access.users.write ||
        $access.departments.read ||
        $access.departments.write
      "
      icon="users"
      :selected="isEmployeeSelected"
      :text="$t('nav.employee')"
    >
      <LayoutsBaseNavigationLinkSecondary
        v-if="$access.users.read || $access.users.write"
        :to="$link.employee.list()"
        :text="$t('nav.employeeList')"
      />
      <LayoutsBaseNavigationLinkSecondary
        v-if="$access.departments.read || $access.departments.write"
        :to="$link.departments.list()"
        :text="$t('nav.departments')"
      />
    </LayoutsBaseNavigationSubNav>

    <LayoutsBaseNavigationLinkPrimary
      v-if="$access.locations.read || $access.locations.write"
      :to="$link.locations.list()"
      icon="map-pin"
      :text="$t('nav.locations')"
    />
    <LayoutsBaseNavigationLinkPrimary
      v-if="$access.locationsAll.read || $access.locationsAll.write"
      :to="$link.refugees()"
      icon="users"
      :text="$t('nav.refugees')"
    />
    <LayoutsBaseNavigationLinkPrimary
      v-if="$access.locationsAll.read || $access.locationsAll.write"
      :to="$link.rooms()"
      icon="users"
      :text="$t('nav.rooms')"
    />
  </div>
</template>

<script lang="ts" setup>
const { $access, $link } = useNuxtApp()
const route = useRoute()

const isEmployeeSelected = computed<boolean>(
  () =>
    typeof route.name === 'string' &&
    (route.name.includes('employee') || route.name.includes('departments')),
)
</script>

<style lang="scss" module>
.link {
  position: relative;
}
</style>
