<template>
  <SharedModeView
    from="scanner"
    to="client"
    :visible="visible"
    :connected="connected"
    :connecting="connecting"
    :related-device="client"
    :message="message"
    :error="error"
  >
    <UiBaseButton v-if="canRetry" @click="retry">
      {{ $t('socket.retry') }}
    </UiBaseButton>
    <UiBaseButton ui="danger" @click="disconnect">
      {{ $t('socket.disconnect') }}
    </UiBaseButton>
  </SharedModeView>
</template>

<script lang="ts" setup>
const __MACROS_emit = defineEmits(["disconnect", "retry"])

const props = defineProps({
  client: { type: Boolean },
  connected: { type: Boolean },
  connecting: { type: Boolean },
  canRetry: { type: Boolean },
  error: null
})

const disconnect = (...args) => __MACROS_emit("disconnect", ...args)
const retry = (...args) => __MACROS_emit("retry", ...args)

const { t } = useI18n()

const visible = computed<boolean>(() => props.connecting || props.connected)

const message = computed<string>(() => {
  return props.connecting
    ? t('socket.scannerView.connecting')
    : !props.client
      ? t('socket.scannerView.noClient')
      : props.connected
        ? t('socket.scannerView.connected')
        : t('socket.scannerView.connect')
})
</script>

<style lang="scss" module>
.container {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
  background: #fff;
  inset: 0;
}

.error {
  color: var(--danger-color);
}
</style>
