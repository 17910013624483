<template>
  <div>
    <LayoutsBaseNavigationLinkPrimary
      v-model:opened="opened"
      :text="text"
      :icon="icon"
      collapsable
    />

    <UiCollapsableView :opened="opened">
      <div :class="$style.list">
        <slot />
      </div>
    </UiCollapsableView>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  icon: null,
  text: null,
  selected: { type: Boolean }
})

const opened = ref<boolean>(props.selected)
</script>

<style lang="scss" module>
.list {
  position: relative;
}
</style>
