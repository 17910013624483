<template>
  <component
    :is="component"
    :type="component === 'button' ? 'button' : undefined"
    :to="to"
    :class="$style.link"
    :active-class="exact ? undefined : $style.link_selected"
    :exact-active-class="exact ? $style.link_selected : undefined"
    @click.prevent="opened = !opened"
  >
    <UiBaseIcon v-if="icon" :name="icon" :class="$style.link__icon" />
    <span :class="$style.link__text">
      {{ text }}
    </span>
    <span
      v-if="collapsable"
      :class="[$style.link__plus, opened && $style.link__plus_opened]"
    />
  </component>
</template>

<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:opened"]);
import type { RouteLocationRaw } from 'vue-router'

const props = defineProps({
  to: null,
  icon: null,
  selected: { type: Boolean },
  collapsable: { type: Boolean },
  exact: { type: Boolean },
  text: null,
  opened: { type: Boolean }
})

const { opened } = __MACROS_useVModel("opened")

const component = computed(() =>
  props.to ? resolveComponent('NuxtLink') : 'button',
)
</script>

<style lang="scss" module>
.link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  color: inherit;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  transition:
    color 0.3s,
    background-color 0.3s;

  @include interact {
    color: var(--accent-color);
  }

  &_selected {
    color: var(--accent-color);
  }

  &_sub {
    position: relative;
    padding: 12px 24px 12px 56px;
    font-size: 14px;
    line-height: 16px;

    &:before {
      position: absolute;
      top: 20px;
      left: 38px;
      width: 6px;
      height: 1px;
      background: currentColor;
      content: '';
    }
  }

  &__icon {
    flex: 0 0 auto;
    align-self: flex-start;
    width: 20px;
    margin-right: 12px;
  }

  &__text {
    flex: 1 1 auto;
  }

  &__plus {
    position: relative;
    width: 12px;
    height: 12px;
    transition: transform var(--duration);
    --duration: 0.3s;

    &:before,
    &:after {
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      width: 100%;
      border-bottom: 2px solid currentColor;
      content: '';
    }

    &:before {
      transform: rotate(90deg);
      transition: transform var(--duration);
    }

    &_opened,
    &_opened:before {
      transform: rotate(180deg);
    }
  }
}
</style>
