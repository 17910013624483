<template>
  <div :class="$style.main">
    <LazyLayoutsBaseHeader
      :class="$style.header"
      :menu-visible="mobileMenuModal.visible"
      @scanner="socket.actions.connect"
    />
    <aside :class="$style.sidebar">
      <LayoutsBaseNavigation />
      <div :class="$style.sidebar__footer">
        <NuxtLink :to="$link.support()" :class="$style.link">
          <UiBaseIcon name="headset" :class="$style.link__icon" />
          <span :class="$style.link__text"> {{ $t('nav.support') }} </span>
        </NuxtLink>
      </div>
    </aside>
    <main :class="$style.content">
      <Transition
        :enter-from-class="$style.loader_inactive"
        :enter-active-class="$style.loader_active"
        :leave-to-class="$style.loader_inactive"
        :leave-active-class="$style.loader_active"
      >
        <div v-if="loading" :class="$style.loader">
          <UiBaseLoader />
        </div>
      </Transition>
      <slot />
    </main>
    <LayoutsMobileMenu
      v-model="mobileMenuModal.visible"
      v-modal="$appModal.id.mobileMenu"
      @scanner="socket.actions.connect"
    />

    <SharedModeScannerModeView
      :client="socket.state.relatedConnection"
      :connecting="socket.state.connecting"
      :connected="socket.state.connected"
      :can-retry="socket.state.canRetry"
      :error="socket.data.error"
      @retry="socket.actions.retry"
      @disconnect="socket.actions.disconnect"
    />
  </div>
</template>

<script lang="ts" setup>
const { $appModal, $link, hook } = useNuxtApp()

const loading = ref(false)

hook('page:start', () => {
  loading.value = true
})

hook('page:transition:finish', () => {
  loading.value = false
})

hook('app:error', () => {
  loading.value = false
})

onMounted(() => (loading.value = false))

const mobileMenuModal = $appModal.create($appModal.id.mobileMenu)

const socket = Mode.useScanner()
</script>

<style lang="scss" module>
.main {
  display: grid;
  min-height: 100vh;
  min-height: 100dvh;

  --header-size: 90px;
  --sidebar-size: 0;

  @include media($from: lg) {
    --sidebar-size: 260px;
  }
}

.header {
  position: fixed;
  z-index: 200;
  height: var(--header-size);
  background: #fff;
  inset: 0 0 auto;
}

.sidebar {
  position: fixed;
  z-index: 150;
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  gap: 24px;
  width: var(--sidebar-size);
  padding-block: 12px;
  background: #fcfcfc;
  inset: var(--header-size) auto 0 0;

  @include media($to: lg) {
    display: none;
  }
}

.content {
  display: grid;
  grid-template-areas: 'content';
  min-width: 0;
  padding: var(--header-size) 0 0 var(--sidebar-size);

  > * {
    grid-area: content;
  }
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  color: inherit;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  transition:
    color 0.3s,
    background-color 0.3s;

  @include interact {
    color: var(--accent-color);
  }

  &_selected {
    color: var(--accent-color);
  }

  &_sub {
    position: relative;
    padding: 12px 24px 12px 56px;
    font-size: 14px;
    line-height: 16px;

    &:before {
      position: absolute;
      top: 20px;
      left: 38px;
      width: 6px;
      height: 1px;
      background: currentColor;
      content: '';
    }
  }

  &__icon {
    flex: 0 0 auto;
    align-self: flex-start;
    width: 20px;
    margin-right: 12px;
  }

  &__text {
    flex: 1 1 auto;
  }

  &__plus {
    position: relative;
    width: 12px;
    height: 12px;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      border-bottom: 2px solid currentColor;
      content: '';
    }

    &:before {
      transform: rotate(90deg);
    }
  }
}

.loader {
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--header-size) 0 0 var(--sidebar-size);
  background: #fff;
  inset: 0;

  &_inactive {
    opacity: 0;
  }

  &_active {
    transition: opacity 0.15s;
  }
}
</style>
