<template>
  <nav :class="$style.nav">
    <LayoutsBaseNavigationContextNavigation
      v-if="hasSelectedLocation"
      @scanner="scanner"
    />
    <LayoutsBaseNavigationAdminNavigation v-else />
  </nav>
</template>

<script lang="ts" setup>
const __MACROS_emit = defineEmits(["scanner"])

const scanner = (...args) => __MACROS_emit("scanner", ...args)

const locationsOptions = await LocationsService.useOptions()

const locationId = useRouteParameter('contextId')

const hasSelectedLocation = computed<boolean>(() =>
  locationsOptions.data.options.some(
    (option) => option.id === locationId.value,
  ),
)
</script>

<style lang="scss" module>
.nav {
  flex: 1 1 auto;
  overflow: auto;
}
</style>
