<template>
  <div>
    <LayoutsBaseNavigationLinkPrimary
      exact
      :to="$link.context.dashboard(locationId)"
      icon="chart-pie"
      :text="$t('nav.dashboard')"
    />
    <LayoutsBaseNavigationLinkPrimary
      v-if="$access.refugees.read || $access.refugees.write"
      :to="$link.context.refugees.list(locationId)"
      icon="users"
      :text="$t('nav.refugees')"
    />
    <LayoutsBaseNavigationLinkPrimary
      v-if="$access.refugees.read || $access.refugees.write"
      :to="$link.context.rooms.list(locationId)"
      icon="map-pin"
      :text="$t('nav.rooms')"
    />
    <LayoutsBaseNavigationSubNav
      v-if="$access.transfers.read || $access.transfers.write"
      icon="transform-filled"
      :selected="isTransfersSelected"
      :text="$t('nav.transfers')"
    >
      <LayoutsBaseNavigationLinkSecondary
        :to="$link.context.transfers.in.list(locationId)"
        :text="$t('nav.transfersIn')"
      />
      <LayoutsBaseNavigationLinkSecondary
        :to="$link.context.transfers.out.list(locationId)"
        :text="$t('nav.transfersOut')"
      />
    </LayoutsBaseNavigationSubNav>
    <LayoutsBaseNavigationLinkPrimary
      v-if="$access.medical.read || $access.medical.write"
      :to="$link.context.medical.list(locationId)"
      icon="medical"
      :text="$t('nav.medical')"
    />
  </div>
</template>

<script lang="ts" setup>
const { $access, $link } = useNuxtApp()

const route = useRoute()

const locationId = useRouteParameter('contextId')

const isTransfersSelected = computed<boolean>(
  () => typeof route.name === 'string' && route.name.includes('transfers'),
)
</script>

<style lang="scss" module>
.link {
  position: relative;
}
</style>
