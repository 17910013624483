<template>
  <Transition
    :enter-from-class="$style.container_inactive"
    :leave-to-class="$style.container_inactive"
    :enter-active-class="$style.container_active"
    :leave-active-class="$style.container_active"
  >
    <div v-if="modelValue" :class="$style.container">
      <div :class="$style.body">
        <LayoutsBaseNavigation @scanner="scanner" />
      </div>
      <div :class="$style.footer">
        <LayoutsBaseNavigationLinkPrimary
          icon="headset"
          :to="$link.support()"
          :text="$t('nav.support')"
        />
        <button
          type="button"
          :class="$style.logout"
          @click="identityService.logout"
        >
          <UiBaseIcon name="logout" :class="$style.logout__icon" />
        </button>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
const __MACROS_emit = defineEmits(["scanner"])

const modelValue = defineModel<boolean>()

const scanner = (...args) => __MACROS_emit("scanner", ...args)

const identityService = useIdentityService()

const route = useRoute()

watch(
  () => route.path,
  (prev, next) => {
    if (prev !== next) modelValue.value = false
  },
)
</script>

<style lang="scss" module>
.container {
  position: fixed;
  z-index: 300;
  display: grid;
  grid-template-rows: 1fr auto;
  width: 300px;
  background: #fff;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  inset: var(--header-size) 0 0 auto;

  @include media($from: lg) {
    display: none;
  }

  &_inactive {
    transform: translateX(100%);
    opacity: 0;
  }

  &_active {
    transition:
      opacity 0.3s,
      transform 0.3s;
  }
}

.footer {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.logout {
  position: relative;
  width: 50px;
  height: 50px;
  background: none;
  transition: background-color 0.3s ease;

  @include interact {
    background: #e9e7ff;
  }

  &__icon {
    width: 24px;
    fill: #e9e7ff;
    stroke: var(--accent-color);
  }
}

.body {
  overflow: auto;
  padding-block: 16px;
}
</style>
